import React, { FC, useContext } from "react";
import * as styles from "./ReportsMain.module.scss";
import { Link } from "gatsby";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import buttonRight from "../../images/buttonArrowRight.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import PagePagination from "../Shared/PagePagination/PagePagination";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import { pathWithoutSpace, ReportsPageContextType } from "./ReportsMainHelpers";
import { UseImageUrl } from "../hooks/UseImageUrl";
import CircleGreenBlur from "../Shared/CircleOutlineAndBlur/Circlegreenblur";
import { LanguageContext } from "../../context/languageContext";
import translate from "../../i18n/translate";

type Props = {
  data: any;
  pageContext: ReportsPageContextType;
};

const ReportsMain: FC<Props> = ({ data, pageContext }) => {
  const { language } = useContext(LanguageContext);
  return (
    <InnerWrapper>
      <div className={styles.reportsContainerWrapper}>
        <div className={styles.reportsMainContainer}>
          <h1 className="heading">
            <span>.</span>Reports
          </h1>
          <p>{translate("report-main-description")}</p>
          <div className={styles.backgroundCircles}>
            <CircleOutline
              isBlurVisible={false}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.contactOutlineCircle}
            />
            <CircleGreenBlur
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.aboutMainCircleBlurGreen}
            />

            <CircleBlur
              initialOpacity={0}
              animateOpacity={0.1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.aboutMainCircleBlurBlue}
            />
          </div>
        </div>

        <div className={styles.reportsMainArticles}>
          <div className={styles.reportsRow}>
            {data.allSanityReport.edges.map(({ node }: any, index: number) => {
              const path = pathWithoutSpace(node.title);
              UseImageUrl(node.mainImage, node._rawMainImage);
              return (
                <div key={index} className={styles.reportsColumnRight}>
                  <Link to={`/reports/${path}`}>
                    <GatsbyImage
                      image={node.mainImage.asset.gatsbyImageData}
                      alt={node?.altMainImage || "Reports Main Image"}
                      className={styles.casePreviewImage}
                    />
                    <h3>.{language === "EN" ? node.title : node.titleDe}</h3>
                    <p>{language === "EN" ? node.subtitle : node.subtitleDe}</p>
                    <span>{translate("read-more")}</span>&nbsp;&nbsp;
                    <img src={buttonRight} alt="Right Button" />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        {pageContext.numberOfReportsPages > 1 && (
          <PagePagination
            pageNumber={pageContext.numberOfReportsPages}
            currentPage={pageContext.currentPage}
            basePathForUrl="/reports"
          />
        )}
      </div>
    </InnerWrapper>
  );
};

export default ReportsMain;
