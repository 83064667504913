import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import ReportsMain from "../components/ReportsUiComponents/ReportsMain";
import { graphql } from "gatsby";
import { SEO } from "../components/seo";

type Props = {
  location: any;
  data: any;
  pageContext: any;
};

const Reports: FC<Props> = ({ data, pageContext }) => {
  return (
    <div>
      <Layout location="/blog">
        <SEO
          title="Reports"
          description="Download exciting cloud reports with latest market analysis and trends, insights about how cloud services will accelerate your business and lead to innovation."
          type="website"
        />
        <>
          <ReportsMain data={data} pageContext={pageContext} />
        </>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query AllSanityReportQuery($skip: Int!, $limit: Int!) {
    allSanityReport(
      limit: $limit
      skip: $skip
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          subtitle
          titleDe
          subtitleDe
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          altMainImage
          altDetailImage
        }
      }
    }
  }
`;

export default Reports;
