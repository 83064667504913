// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ReportsMain-module--aboutMainCircleBlurBlue--tRRoJ";
export var aboutMainCircleBlurGreen = "ReportsMain-module--aboutMainCircleBlurGreen--6T3qQ";
export var backgroundCircles = "ReportsMain-module--backgroundCircles--QxcJQ";
export var backgroundCirclesBlogArticles = "ReportsMain-module--backgroundCirclesBlogArticles--m6Ohi";
export var blogsCircleBlueRight = "ReportsMain-module--blogsCircleBlueRight--q0Jvh";
export var casePreviewImage = "ReportsMain-module--casePreviewImage--ne+ps";
export var contactOutlineCircle = "ReportsMain-module--contactOutlineCircle--VRvYr";
export var fadeInBottomRight = "ReportsMain-module--fadeInBottomRight--Cz3rp";
export var reportsColumnLeft = "ReportsMain-module--reportsColumnLeft--FVK6Z";
export var reportsColumnRight = "ReportsMain-module--reportsColumnRight--fUXj3";
export var reportsContainerWrapper = "ReportsMain-module--reportsContainerWrapper--vLNcV";
export var reportsMainArticles = "ReportsMain-module--reportsMainArticles--v1RKc";
export var reportsMainContainer = "ReportsMain-module--reportsMainContainer--rhMrQ";
export var reportsMainTags = "ReportsMain-module--reportsMainTags--3C+qE";
export var reportsRow = "ReportsMain-module--reportsRow--FRiku";
export var reportsTag = "ReportsMain-module--reportsTag--Qphax";