import { Link } from "gatsby";
import React, { FC } from "react";
import whitelinePagination from "../../../images/whitelinePagination.svg";
import * as styles from "./PagePagination.module.scss";

type Props = {
  pageNumber: any;
  currentPage: any;
  basePathForUrl: string;
};

const PagePagination: FC<Props> = ({
  pageNumber,
  currentPage,
  basePathForUrl,
}) => {
  return (
    <div className={styles.paginationWrapper}>
      {Array.from({ length: pageNumber }, (_, i) => (
        <Link
          style={{ color: currentPage === i + 1 ? "white" : "#666" }}
          key={`pagination-number${i + 1}`}
          to={`${basePathForUrl}${i === 0 ? "" : `/${i + 1}`}`}
        >
          {i < 9 ? `0${i + 1}` : `${i + 1}`}
          {i + 1 < pageNumber && currentPage === i + 1 ? (
            <img src={whitelinePagination} alt="white line" />
          ) : null}
        </Link>
      ))}
    </div>
  );
};

export default PagePagination;
