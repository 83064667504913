export const pathWithoutSpace = (path: string) => {
  const convertedPath = path
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
  return convertedPath;
};

export type ReportsPageContextType = {
  currentPage: number;
  limit: number;
  numberOfReportsPages: number;
  skip: number;
  tags: string[];
};
